





































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import { RoleType, UserShortResource } from '@/store/types'

@Component({
  inheritAttrs: false,
})
export default class UsersSingleSearch extends Mixins(DetectSafariMixin, NotifyMixin) {
  @Prop({ default: null })
  private value!: UserShortResource

  @Prop({ default: 'Поиск пользователей' })
  private label!: string

  @Prop({ default: 'Начните вводить для поиска' })
  private placeholder!: string

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: () => ([]) })
  private roleTypes!: RoleType[]

  private search = ''
  private isLoading = false
  private userSelected: UserShortResource | null = null
  private usersFound: UserShortResource[] = []

  private mounted () {
    if (this.value) {
      this.userSelected = this.value
      this.usersFound = [this.value]
    }
  }

  private handleInput (value: UserShortResource) {
    this.$emit('update:value', value)
  }

  @Bind
  @Debounce(500)
  private handleSearch (query: string) {
    if (this.isLoading) return

    this.isLoading = true

    DictionaryModule.searchUsers({ query: query, roleTypes: this.roleTypes })
      .then((response: UserShortResource[]) => {
        this.usersFound = response
      })
      .catch(this.notifyError)
      .finally(() => (this.isLoading = false))
  }

  @Watch('search')
  private watchSearch (value: string) {
    if (value) {
      this.handleSearch(value)
    }
  }
}
